import AppConfig from "~/config/AppConfig";

const DataDictionary = Object.freeze({
    DirectDealListing: {
        CAMPAIGN_CLASS_DELIVERY_LEVELS: "/campaign-class-delivery-levels"
    },
    DirectDealCreate: {
        CUSTOM_ATTRIBUTES: "/directdeals/attributes",
        CAMPAIGN_WEIGHTS: "/campaign-weights",
        CAMPAIGN_CLASS_DELIVERY_LEVELS: "/campaign-class-delivery-levels",
        AUDIENCE_TARGETING: "/targeting-dictionaries",
        ACCOUNT: "/account",
    },
    DirectDealManage: {
        CUSTOM_ATTRIBUTES: "/directdeals/attributes",
        AD_FORMATS: "/ad-formats",
        CAMPAIGN_WEIGHTS: "/campaign-weights",
        CAMPAIGN_CLASS_DELIVERY_LEVELS: "/campaign-class-delivery-levels",
        AUDIENCE_TARGETING: "/targeting-dictionaries",
        ACCOUNT: "/account",
        AD_TARGETING: "/ad-targeting-dictionaries",
    },
    AdXDealListing: {
        CAMPAIGN_CLASS_DELIVERY_LEVELS: "/campaign-class-delivery-levels",
        SSP_ACCOUNTS: "/ssp-accounts",
    },
    AdXDealCreate: {
        CAMPAIGN_WEIGHTS: "/campaign-weights",
        CAMPAIGN_CLASS_DELIVERY_LEVELS: "/campaign-class-delivery-levels",
        SSP_CHANNELS: "/ssp-channels",
        SSP_ACCOUNTS: "/ssp-accounts",
        AUDIENCE_TARGETING: "/targeting-dictionaries",
    },
    AdXDealManage: {
        CAMPAIGN_WEIGHTS: "/campaign-weights",
        CAMPAIGN_CLASS_DELIVERY_LEVELS: "/campaign-class-delivery-levels",
        AUDIENCE_TARGETING: "/targeting-dictionaries",
    },
    NetworkItemCreate: {
        CAMPAIGN_WEIGHTS: "/campaign-weights",
        CAMPAIGN_CLASS_DELIVERY_LEVELS: "/campaign-class-delivery-levels",
        NETWORK_CHANNELS: "/network-channels",
        NETWORK_ACCOUNTS: "/network-accounts",
        AUDIENCE_TARGETING: "/targeting-dictionaries",
    },
    NetworkItemManage: {
        CAMPAIGN_WEIGHTS: "/campaign-weights",
        CAMPAIGN_CLASS_DELIVERY_LEVELS: "/campaign-class-delivery-levels",
        AUDIENCE_TARGETING: "/targeting-dictionaries",
    },
    NetworkItemListing: {
        NETWORK_CHANNELS: "/network-channels",
        CAMPAIGN_CLASS_DELIVERY_LEVELS: "/campaign-class-delivery-levels",
        NETWORK_ACCOUNTS: "/network-accounts",
    },
    Analytics: {
        "/publisher": "/publisher",
        "/adspot": "/adspot",
        "/asset": "/asset",
        "/country": "/country",
        "marketers": `${AppConfig.apiBaseUrl}marketers`,
        "adagencies": `${AppConfig.apiBaseUrl}adagencies`,
        "brand": `${AppConfig.apiBaseUrl}brand`,
        "/user-gender": "/user-gender",
        "/user-age": "/user-age",
        "/campaign-class-delivery-levels": "/campaign-class-delivery-levels",
        "/network-channels": "/network-channels",
        "/network-accounts": "/network-accounts",
        "/global-adformat": "/global-adformat",
        "/custom_dimensions": "/custom_dimensions",
    },
});

export default DataDictionary;