import { useEffect } from 'react';
import { AppLogo, FavIcon } from '~/enums/AppLogo';

export default function useDocumentTitle({
  appLogo = AppLogo.MANAGE,
  appTitle = 'Manage',
  documentTitle = 'Manage',
  favIcon = FavIcon.MANAGE,
  bodyClassName = '',
}) {
  return useEffect(() => {
    document.title = documentTitle || '';
    document.querySelector('#favicon').setAttribute('href', favIcon);
    document.body.className = bodyClassName || '';
    const appTitleElement = document.querySelector('#app-title');
    const appLogoElement = document.querySelector('#app-logo');
    if (appTitleElement) appTitleElement.innerText = appTitle || '';
    if (appLogoElement) appLogoElement.src = appLogo || '';
  }, [documentTitle, appTitle]);
}
